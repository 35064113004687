<template>
  <div id="main-wrapper" class="inner-pages tradcalc-page">
    <div class="section banner-holder">
      <div class="title-holder t-white t-center center-div wow fadeIn">
        <h2>Trading Calculators</h2>
        <p>Calculations made easy for you</p>
      </div>
    </div>
    <div class="section bonus-trade trading-calc">
      <div class="wrapper">
        <div class="title-holder wow fadeIn">
          <h5>Never miss out on trading profits due to bad calculations!</h5>
          <br />
          <p>
            Make accurate assessments in blink of an eye by using our Trading
            Calculators. We are committed to help you make the most out of your
            trades by saving time and focusing all the attention on crucial
            trading decisions instead of engaging in tedious calculations. We
            have handpicked the most useful calculators for trading and included
            them here for you. You do not have to download anything. Just choose
            the calculator you want, enter your inputs, and the calculator will
            give you the answer!
          </p>
          <div class="btn-holder">
            <a
              @click="calculatorType = 'Swap'"
              class="btn btn-llblue"
              :class="{ activeButton: calculatorType == 'Swap' }"
              ><img src="assets/images/calc-ico.webp" alt="AAAFx" title="AAAFx" width="30" height="30" class="fluid-img" />Swap
              Calculator
            </a>
            <a
              @click="calculatorType = 'Margin'"
              class="btn btn-llblue"
              :class="{ activeButton: calculatorType == 'Margin' }"
              ><img src="assets/images/calc-ico.webp" alt="AAAFx" title="AAAFx" width="30" height="30" class="fluid-img" />Margin
              Calculator
            </a>
            <a
              @click="calculatorType = 'Pip'"
              class="btn btn-llblue"
              :class="{ activeButton: calculatorType == 'Pip' }"
              ><img src="assets/images/calc-ico.webp" alt="AAAFx" title="AAAFx" width="30" height="30" class="fluid-img" />Pip
              Calculator
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="section forex-calc">
      <div class="wrapper">
        <div class="title-holder">
          <h3>{{ calculatorType }} Calculator</h3>
        </div>
        <div class="form-holder calcu-holder">
          <swap v-if="calculatorType == 'Swap'" />
          <margin v-if="calculatorType == 'Margin'" />
          <pip v-if="calculatorType == 'Pip'" />
          <!-- <ul class="clearfix">
						<li class="width30 f-left">
							<label for="">Segment:</label>
							<select name="" class="select2">
								<option value="">Currency</option>
							</select>
						</li>
						<li class="width30 f-left">
							<label for="">Trading Instrument:</label>
							<select  name="" class="select2">
								<option value="">Please Select</option>
							</select>
						</li>
						<li class="width30 f-left">
							<label for="">Account Currency:</label>
							<select  name="" class="select2">
								<option value="">EUR</option>
							</select>
						</li>
						<li class="width40 f-left">
							<label for="">Leverage For Margin:</label>
							<select  name="" class="select2">
								<option value="">Currency</option>
							</select>
						</li>
						<li class="width40 f-left">
							<label for="">Account Type:</label>
							<select  name="" class="select2">
								<option value="">Please Select</option>
							</select>
						</li>
						<li class="width40 f-left">
							<label for="">Position Size:</label>
							<select  name="" class="select2">
								<option value="">EUR</option>
							</select>
						</li>
						<li class="width40 f-left">
							<label for="">No. of Days:</label>
							<select  name="" class="select2">
								<option value="">EUR</option>
							</select>
						</li>
						<li class="width50 f-left">
							<label for="">Commission:</label>
							<input type="text" name="" value="" placeholder="0.0">
						</li>
						<li class="width50 f-left">
							<label for="">Required Margin:</label>
							<input type="text" name="" value="" placeholder="0.0">
						</li>
						<li class="width50 f-left">
							<label for="">Pip Value:</label>
							<input type="text" name="" value="" placeholder="0.0">
						</li>
						<li class="width50 f-left">
							<label for="">Swap Long:</label>
							<input type="text" name="" value="" placeholder="0.0">
						</li>
						<li class="width50 f-left">
							<label for="">Swap Short:</label>
							<input type="text" name="" value="" placeholder="0.0">
						</li>
					</ul> -->
        </div>
        <!-- <div class="btn-holder">
					<input type="submit" name="" value="CALCULATE" class="btn btn-bluey">
				</div> -->
      </div>
    </div>
  </div>
</template>

<script>
import Swap from "@/components/calculators/Swap";
import Margin from "@/components/calculators/Margin";
import Pip from "@/components/calculators/Pip";

export default {
  components: {
    Swap,
    Margin,
    Pip,
  },
  data() {
    return {
      calculatorType: "Swap",
    };
  },
};
</script>
